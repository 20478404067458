import {createGlobalStyle} from "styled-components";

let colors = {
    primaryMain: "#1976d2",
    primaryLight: "#cad0d7",
    successMain: "#4a801a",
    successLight: "#f4faf6",
    warningMain: "#d92c01",
    warningLight: "#fff4ec",
    errorMain: "#e20037",
    errorLight: "#fef5f7",
    infoMain: "#001e6d",
    infoLight: "#e3eafa",
    grey5: "#424a52",
    grey4: "#666",
    grey3: "#999",
    grey2: "#ccc",
    grey1: "#eee",
    grey0: "#f8f8f8",
    commonWhite: "#fff",
    textDark: "#000",
};

if (window._env_.REACT_APP_PROJECT === 'RNA') {
    colors = {
        ...colors,
        primaryMain: "#e20037",
        primaryLight: "#fef5f7",
        successMain: "#4a801a",
        successLight: "#f4faf6",
        warningMain: "#d92c01",
        warningLight: "#fff4ec",
        infoMain: "#001e6d",
        infoLight: "#e3eafa",
    };
}

export const ThemeColors = colors;

const GlobalStyles = createGlobalStyle`
    :root {
        --primary-main: ${colors.primaryMain};
        --primary-light: ${colors.primaryLight};
        --success-main: ${colors.successMain};
        --success-light: ${colors.successLight};
        --warning-main: ${colors.warningMain};
        --warning-light: ${colors.warningLight};
        --info-main: ${colors.infoMain};
        --info-light: ${colors.infoLight};
        --grey-5: ${colors.grey5};
        --grey-4: ${colors.grey4};
        --grey-3: ${colors.grey3};
        --grey-2: ${colors.grey2};
        --grey-1: ${colors.grey1};
        --grey-0: ${colors.grey0};
        --common-white: ${colors.commonWhite};
        --text-dark: ${colors.textDark};
    }
`;
export default GlobalStyles;
