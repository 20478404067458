import AbstractService from "./AbstractService";
import {Dictionary} from "@/types/main";
import SchoolItiParameters, {BillingPaymentPeriods} from "@/models/SchoolItiParameters";
import {parseDate} from "@/plugins/dateFns";
import store from "@/utils/Store";
import urlJoin from "url-join";

type SchoolItiParametersPartial = Omit<SchoolItiParameters, "LOGO_IMAGE_URL" | "TOOLTIP_IMAGE_URL" | "HAS_TREASURY_ORMC">;

class SchoolItiParametersService extends AbstractService {

    constructor() {
        super("/public/fiti/subscription/dynamic/settings");
    }

    async getSettings() {
        const {data} = await this.get<Dictionary<string>>();
        const schoolYear = parseInt(data.SCHOLAR_YEAR);

        return {
            ...data,
            CB_DELAYED_PAYMENT_DEAD_LINE: parseDate(data.CB_DELAYED_PAYMENT_DEAD_LINE),
            BILLING_PAYMENT_PERIODS: BillingPaymentPeriods.parse(data.BILLING_PAYMENT_PERIODS),
            SCHOLAR_YEAR: schoolYear,
            TAX_NOTICE_YEAR: schoolYear - 1,
            REG_INF_PHOTO_MANDATORY: data.REG_INF_PHOTO_MANDATORY === "true",
            SUBSCRIPTIONS_OPEN: data.SUBSCRIPTIONS_OPEN === "true",
            REG_INF_MIN_BIRTH_DATE: parseDate(data.REG_INF_MIN_BIRTH_DATE),
            REG_INF_FOSTER_FAMILY: data.REG_INF_FOSTER_FAMILY === "true",
            REG_MANAGE_FAMILY_RATIO: data.REG_MANAGE_FAMILY_RATIO === "true",
            REG_INF_REFUGEE_CLAIMANT: data.REG_INF_REFUGEE_CLAIMANT === "true",
            REG_INF_REVIEW_REQUEST: data.REG_INF_REVIEW_REQUEST === "true",
            PREVIOUS_YEAR_ASSIGNMENT_END_SUBS_DATE: data.PREVIOUS_YEAR_ASSIGNMENT_END_SUBS_DATE ? parseDate(data.PREVIOUS_YEAR_ASSIGNMENT_END_SUBS_DATE) : undefined,
            VERIFY_CONTACT_EMAIL: data.VERIFY_CONTACT_EMAIL === "true",
            VERIFY_CONTACT_PHONE: data.VERIFY_CONTACT_PHONE === "true",
            REG_INF_SUGGEST_PHONE_TRAVELER_INFORMATION: data.REG_INF_SUGGEST_PHONE_TRAVELER_INFORMATION === "true",
            REG_INF_SUGGEST_EMAIL_ACTUALITY: data.REG_INF_SUGGEST_EMAIL_ACTUALITY === "true",
            REG_INF_RGPD_POLITIC: data.REG_INF_RGPD_POLITIC?.toString(),
            REG_INF_WELCOME_INFORMATION: data.REG_INF_WELCOME_INFORMATION?.toString(),
            REG_INF_DISPLAY_FARE: data.REG_INF_DISPLAY_FARE === "true",
            REG_INF_PHOTO_RESTRICT_CONFORMITY: data.REG_INF_PHOTO_RESTRICT_CONFORMITY === "true",
            REG_DROP_WISH_NON_BLOCKING: data.REG_DROP_WISH_NON_BLOCKING === "true",
            REG_DROP_IN_CENTER_ALLOW_ONLINE_REGISTRATION: data.REG_DROP_IN_CENTER_ALLOW_ONLINE_REGISTRATION === "true",
            REG_INF_STEP_1_HELP: data.REG_INF_STEP_1_HELP?.toString(),
            REG_INF_STEP_2_HELP: data.REG_INF_STEP_2_HELP?.toString(),
            REG_INF_STEP_3_HELP: data.REG_INF_STEP_3_HELP?.toString(),
            REG_INF_TICKETING_EXTERNAL_NUMBER_ENABLED: data.REG_INF_TICKETING_EXTERNAL_NUMBER_ENABLED === "true",
            REG_INF_TICKETING_EXTERNAL_NUMBER_MESSAGE: data.REG_INF_TICKETING_EXTERNAL_NUMBER_MESSAGE?.toString(),
            REG_INF_MAP_LAT_LNG_ZOOM: data.REG_INF_MAP_LAT_LNG_ZOOM?.toString(),
            REG_INF_LR_SCHOOL_OPTION_COMMENT: data.REG_INF_LR_SCHOOL_OPTION_COMMENT === "true",
            REG_INF_LOGO_IMAGE: data.REG_INF_LOGO_IMAGE?.toString(),
            REG_INF_LR_BIRTH_DATE_REQUIRED: data.REG_INF_LR_BIRTH_DATE_REQUIRED === "true",
            REG_INF_COMMENT: data.REG_INF_COMMENT === "true",
            REG_INF_TOOLTIP_IMAGE: data.REG_INF_TOOLTIP_IMAGE?.toString(),
            REG_INF_LR2_WISH_MANAGEMENT: data.REG_INF_LR2_WISH_MANAGEMENT === "true",
            REG_SUBSCRIPTION_DURING_SCHOOL_YEAR: data.REG_SUBSCRIPTION_DURING_SCHOOL_YEAR === "true",
        } as SchoolItiParametersPartial;
    }

    getHelpertextRegistrationPopin() {
        return this.get("/helper-popin");
    }

    getParameterFile(parameterKey: string) {
        return this.getFile(["/file/" + parameterKey]);
    }

    getAssetsUrl(src) {
        return urlJoin(this.http.defaults.baseURL || "", "/public/assets/", store.commonCode, src);
    }

    getLogoUrl() {
        return this.getAssetsUrl("/logo");
    }

    getTooltipUrl() {
        return this.getAssetsUrl("/tooltip");
    }
}

export default new SchoolItiParametersService();
