/* eslint-disable import/first */
global.__webpack_public_path__ = window._env_.REACT_APP_APPLICATION_SUB_PATH;

import UtilityFunctions from "@/utils/UtilityFunctions";
import "frontend.common/src/polyfill";

import React from "react";
import ReactDOM from "react-dom";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import ThemeColors from "./ThemeColors";
import "@/assets/css/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@/plugins/FontAwesome";
import KeyCloakService from "@/service/KeyCloakService";
import {isProd} from "frontend.common/src/EnvironmentConstants";
import store from "@/utils/Store";

// Google Tag Manager
import TagManager from "react-gtm-module";

if (isProd) {
    TagManager.initialize({
        gtmId: window._env_.REACT_APP_GTM_ID,
    });
}

function loadCommonCode() {
    if (!window._env_.REACT_APP_DEFAULT_COMMON_CODE) {
        // On parse le hash qui est sous la forme #/common_code/....
        const hash = window.location.hash || "";
        const commonCode = hash.match(new RegExp("#/([A-Za-z]+)?(/.*)?"))?.[1];

        if (store.commonCode && store.commonCode !== commonCode) {
            window.location.reload();
        }

        if (commonCode) {
            store.commonCode = commonCode;
        }
    }
}

loadCommonCode();
window.addEventListener("hashchange", loadCommonCode);

KeyCloakService.init()
    .then(() => {
        ReactDOM.render(
            <React.StrictMode>
                <ThemeColors/>
                <App/>
            </React.StrictMode>,
            document.getElementById("root")
        );

        // If you want to start measuring performance in your app, pass a function
        // to log results (for example: reportWebVitals(console.log))
        // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
        reportWebVitals();
    })
    .catch((err) => {
        console.error(err?.message ?? err);

        const reload = () => {
            setTimeout(() => window.location.reload(), 1);
        };

        const commonCodesByProjects = {
            "AGGLO": [
                "dax",
                "mdm",
                "macs",
                "stgilles",
                "can",
                "montaigu",
                "redon",
                "cot",
                "prn",
            ],
            "BRE": [
                "anvan",
                "anren",
                "anbri",
                "anqui",
            ],
        };

        const commonCodes = commonCodesByProjects[window._env_.REACT_APP_PROJECT] || [];

        ReactDOM.render(
            <div style={{paddingLeft: "2rem"}}>
                <h2>404 - Page non trouvée</h2>
                {UtilityFunctions.isTestingMode() &&
                    <div style={{display: "flex", flexDirection: "column"}}>
                        {commonCodes.map(commonCode => (
                            <div key={commonCode}>
                                <a href={"#/" + commonCode} onClick={reload}>{commonCode}</a>
                            </div>
                        ))}
                    </div>
                }
            </div>,
            document.getElementById("root")
        );
    });