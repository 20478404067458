import {createTheme, Theme} from "@mui/material";
import {frFR} from "@mui/material/locale";
import {ThemeColors} from "./ThemeColors";

/*
 * TS définition added properties
 */
declare module "@mui/material/styles" {
    interface Theme {
        colors: {
            textDark: string,
            lightBackground: string,
            darkBorder: string,
            lightBorder: string,
            highlight: string,
            highlightBackground: string,
        },
        default: {
            spacing: number
        }
    }

    // allow configuration using `createTheme`
    interface ThemeOptions {
        colors?: Partial<Theme["colors"]>;
        default?: Partial<Theme["default"]>;
    }

    interface Palette {
        customGrey: Palette["primary"];
    }

    interface PaletteOptions {
        customGrey: PaletteOptions["primary"];
    }

}


// END
const theme: Theme = createTheme(
    {
        colors: {
            darkBorder: ThemeColors.grey5,
            textDark: ThemeColors.grey5,
            lightBackground: ThemeColors.grey0,
            lightBorder: ThemeColors.grey1,
            highlight: ThemeColors.primaryMain,
            highlightBackground: ThemeColors.primaryLight,
        },
        default: {
            spacing: 3,
        },
        palette: {
            primary: {
                main: ThemeColors.primaryMain,
            },
            secondary: {
                main: "#FFF",
            },
            info: {
                main: ThemeColors.infoMain,
                light: ThemeColors.infoLight,
            },
            error: {
                main: ThemeColors.errorMain,
                light: ThemeColors.errorLight,
            },
            success: {
                main: ThemeColors.successMain,
                light: ThemeColors.successLight,
            },
            warning: {
                main: ThemeColors.warningMain,
                light: ThemeColors.warningLight,
            },
            customGrey: {
                main: ThemeColors.grey0,
                dark: ThemeColors.grey2,
                contrastText: ThemeColors.grey4,
            },
        },
        typography: {
            fontSize: 14,
            h1: {
                color: ThemeColors.grey5,
            },
            h2: {
                fontSize: 20,
                fontWeight: 500,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.22,
                letterSpacing: 0.46,
                color: ThemeColors.grey5,
            },
            h3: {
                color: ThemeColors.grey5,
            },
            subtitle1: {
                fontSize: 20,
                fontWeight: 500,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.22,
                letterSpacing: 0.46,
            },
            subtitle2: {
                fontSize: 16,
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.22,
                letterSpacing: 0.37,
            },
            body2: {
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "1.22",
                letterSpacing: "0.32px",
                color: ThemeColors.grey5,
            },
            caption: {
                fontSize: 12,
                color: ThemeColors.grey4,
            },
        },
        components: {
            MuiButton: {
                defaultProps: {
                    variant: "contained",
                },
                styleOverrides: {
                    root: {
                        fontWeight: 500,
                        fontSize: "18px",
                        textTransform: "none",
                        "&.MuiButton-contained:hover": {
                            backgroundColor: ThemeColors.primaryMain,
                        },
                    },
                },
            },
            MuiButtonGroup: {
                styleOverrides: {
                    root: {
                        backgroundColor: ThemeColors.commonWhite,
                    },
                },
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        fontWeight: 500,
                        fontSize: "14px",
                        marginTop: "15px",
                        marginBottom: "15px",
                        "&:hover": {
                            textDecoration: "none",
                            color: ThemeColors.primaryMain,
                        },
                        "&[disabled]": {
                            color: "grey",
                            cursor: "default",
                            textDecoration: "none",
                        },
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        color: ThemeColors.grey4,
                        "&.Mui-focused": {
                            color: ThemeColors.grey4,
                        },
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        color: ThemeColors.textDark,
                        "&.Mui-focused::after": {
                            borderBottom: "solid 1px",
                            borderBottomColor: ThemeColors.grey2,
                        },
                    },
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        color: ThemeColors.textDark,
                    },
                },
            },
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        color: ThemeColors.grey5,
                        backgroundColor: ThemeColors.commonWhite,
                        boxShadow: "none",
                    },
                },
            },
            MuiLinearProgress: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#fef5f7",
                        height: "8px",
                    },
                    bar: {
                        borderRadius: "0 4px 4px 0",
                    },
                },
            },
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        display: "flex",
                    },
                },
            },
            MuiDrawer: {
                styleOverrides: {
                    paper: ({ownerState}) => ({
                        ...(ownerState.overridestheme === "top-shadow" ? {
                            boxShadow: "0px -3px 6px -5px #333",
                            borderRadius: "15px 15px 0 0",
                            padding: "20px",
                            maxWidth: "900px",
                            margin: "auto",
                            maxHeight: "90%",
                        } : null),

                    }),
                },
            },
            MuiListItemText: {
                styleOverrides: {
                    root: {
                        color: ThemeColors.textDark,
                    },
                },
            },
            MuiGrid: {
                defaultProps: {
                    spacing: 2,
                },
            },
            MuiMobileStepper: {
                styleOverrides: {
                    dotActive: {
                        fontWeight: "bold",
                        backgroundColor: ThemeColors.grey5,
                    },
                },
            },

            MuiStepIcon: {
                styleOverrides: {
                    root: {
                        fontWeight: "bold",
                        border: "solid #eeeeee",
                        borderRadius: "20%",
                        color: ThemeColors.grey0,
                        "& .MuiStepIcon-text": {
                            fill: ThemeColors.grey5,
                        },
                    },
                },
            },
        },
    },
    frFR
);
export default theme;